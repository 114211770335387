import { PlanificationContext } from "../../views/planificationView/planificationView";
import { ReactComponent as ActualizarIcon } from "../../assets/icons/guardar.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import UploadArchive from "../uploadArchive/uploadArchive";
import { URI_API, getHeaders } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import { DayPicker } from "react-day-picker";
import { Modal, Box, Autocomplete, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import "react-day-picker/dist/style.css";
import "./planificationCalendar.scss";
import es from "date-fns/locale/es";

export default function PlanificationCalendar() {
    const { role, availableNurses } = useContext(PlanificationContext);
    const [showModal, setShowModal] = useState(false);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());
    const [januaryMonth, setJanuaryMonth] = useState<any>([]);
    const [februaryMonth, setFebruaryMonth] = useState<any>([]);
    const [marchMonth, setMarchMonth] = useState<any>([]);
    const [aprilMonth, setAprilMonth] = useState<any>([]);
    const [mayMonth, setMayMonth] = useState<any>([]);
    const [juneMonth, setJuneMonth] = useState<any>([]);
    const [julyMonth, setJulyMonth] = useState<any>([]);
    const [augustMonth, setAugustMonth] = useState<any>([]);
    const [septemberMonth, setSeptemberMonth] = useState<any>([]);
    const [octoberMonth, setOctoberMonth] = useState<any>([]);
    const [novemberMonth, setNovemberMonth] = useState<any>([]);
    const [decemberMonth, setDecemberMonth] = useState<any>([]);
    const [januaryMonthOff, setJanuaryMonthOff] = useState<any>([]);
    const [februaryMonthOff, setFebruaryMonthOff] = useState<any>([]);
    const [marchMonthOff, setMarchMonthOff] = useState<any>([]);
    const [aprilMonthOff, setAprilMonthOff] = useState<any>([]);
    const [mayMonthOff, setMayMonthOff] = useState<any>([]);
    const [juneMonthOff, setJuneMonthOff] = useState<any>([]);
    const [julyMonthOff, setJulyMonthOff] = useState<any>([]);
    const [augustMonthOff, setAugustMonthOff] = useState<any>([]);
    const [septemberMonthOff, setSeptemberMonthOff] = useState<any>([]);
    const [octoberMonthOff, setOctoberMonthOff] = useState<any>([]);
    const [novemberMonthOff, setNovemberMonthOff] = useState<any>([]);
    const [decemberMonthOff, setDecemberMonthOff] = useState<any>([]);
    const [nurseNomina, setNurseNomina] = useState<any>("");
    const [selectedNurse, setSelectedNurse] = useState<any>({});
    const [, updateState] = useState<any>();
    const [isUpdating, setIsUpdating] = useState(false);
    const [nurseClassxSelected, setNurseClassxSelected] = useState({
        class: "",
    });
    const [newClassxSelected, setNewClassxSelected] = useState("");
    const [nursesByHospital, setNursesByHospital] = useState([]);

    const classx = [
        "AUX A",
        "AUX B",
        "AUX C",
        "COORD",
        "ENF A",
        "ENF B",
        "ENF C",
        "ENF D",
        "ENF ESP",
        "ENF SUB",
        "JEFE ENF",
    ];

    const dayOffModifierStyle = {
        backgroundColor: "#B78DB7",
        color: "white",
    };

    const dayModifierStyle = {
        backgroundColor: "#62098C",
        color: "white",
    };

    const sendChanges = () => {
        setIsUpdating(true);

        let changedDays: any[] = [].concat(
            januaryMonth,
            februaryMonth,
            marchMonth,
            aprilMonth,
            mayMonth,
            juneMonth,
            julyMonth,
            augustMonth,
            septemberMonth,
            octoberMonth,
            novemberMonth,
            decemberMonth
        );
        changedDays = changedDays.map((day) => day.toISOString().split("T")[0]);

        let changedDaysOffs: any[] = [].concat(
            januaryMonthOff,
            februaryMonthOff,
            marchMonthOff,
            aprilMonthOff,
            mayMonthOff,
            juneMonthOff,
            julyMonthOff,
            augustMonthOff,
            septemberMonthOff,
            octoberMonthOff,
            novemberMonthOff,
            decemberMonthOff
        );
        changedDaysOffs = changedDaysOffs.map(
            (day) => day.toISOString().split("T")[0]
        );

        const payload = {
            days: changedDays,
            nomina: nurseNomina,
            work_schedule_id: selectedNurse.work_schedule_id,
            nurse_id: selectedNurse.nurse_id,
            classx: newClassxSelected,
            days_offs: changedDaysOffs,
        };

        fetch(`${URI_API}/nurse/save_changes`, getHeaders("POST", payload))
            .then((res) => res.json())
            .then(() => {
                toast("Actualizado", {
                    type: "success",
                    containerId: "PC",
                });
            })
            .catch(() => {
                toast("Error al actualizar.", {
                    type: "error",
                    containerId: "PC",
                });
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };

    useEffect(() => {
        fetch(`${URI_API}/getNursesByHospital`, getHeaders())
            .then((res) => res.json())
            .then((data) => {
                setNursesByHospital(data);
            });
    }, []);

    useEffect(() => {
        if (role !== "") {
            setShowModal(false);
        }
    }, [role]);

    const forceUpdate = useCallback(() => {
        updateState({});
    }, []);

    const getNurseClassx = (nomina: any) => {
        fetch(`${URI_API}/nurses`, getHeaders())
            .then((res) => res.json())
            .then((data) => {
                setNurseClassxSelected(
                    data.filter((nurse: any) => nurse.payroll === nomina)[0]
                );
            });
    };

    const onSelectNurse = (nomina: any) => {
        forceUpdate();
        fetch(
            `${URI_API}/nurse/schedule_info?` +
                new URLSearchParams({
                    nomina,
                }),
            getHeaders()
        )
            .then((res) => res.json())
            .then((data) => {
                setSelectedNurse(data);
                let arrAuxJanuary = [];
                let arrAuxFebruary = [];
                let arrAuxMarch = [];
                let arrAuxApril = [];
                let arrAuxMay = [];
                let arrAuxJune = [];
                let arrAuxJuly = [];
                let arrAuxAugust = [];
                let arrAuxSeptember = [];
                let arrAuxOctober = [];
                let arrAuxNovember = [];
                let arrAuxDecember = [];
                let arrAuxJanuaryOff = [];
                let arrAuxFebruaryOff = [];
                let arrAuxMarchOff = [];
                let arrAuxAprilOff = [];
                let arrAuxMayOff = [];
                let arrAuxJuneOff = [];
                let arrAuxJulyOff = [];
                let arrAuxAugustOff = [];
                let arrAuxSeptemberOff = [];
                let arrAuxOctoberOff = [];
                let arrAuxNovemberOff = [];
                let arrAuxDecemberOff = [];
                for (let fecha of data.Dias_Trabajo) {
                    let arrAux = [];
                    let aux = new Date(fecha);
                    switch (aux.getMonth()) {
                        case 0:
                            arrAuxJanuary.push(aux);
                            break;
                        case 1:
                            arrAuxFebruary.push(aux);

                            break;
                        case 2:
                            arrAuxMarch.push(aux);

                            break;
                        case 3:
                            arrAuxApril.push(aux);

                            break;
                        case 4:
                            arrAuxMay.push(aux);

                            break;
                        case 5:
                            arrAuxJune.push(aux);

                            break;
                        case 6:
                            arrAuxJuly.push(aux);
                            break;
                        case 7:
                            arrAuxAugust.push(aux);

                            break;
                        case 8:
                            arrAuxSeptember.push(aux);
                            break;
                        case 9:
                            arrAuxOctober.push(aux);
                            break;
                        case 10:
                            arrAuxNovember.push(aux);

                            break;
                        case 11:
                            arrAuxDecember.push(aux);

                            break;
                    }
                }
                setJanuaryMonth(arrAuxJanuary);
                setFebruaryMonth(arrAuxFebruary);
                setMarchMonth(arrAuxMarch);
                setAprilMonth(arrAuxApril);
                setMayMonth(arrAuxMay);
                setJuneMonth(arrAuxJune);
                setJulyMonth(arrAuxJuly);
                setAugustMonth(arrAuxAugust);
                setSeptemberMonth(arrAuxSeptember);
                setOctoberMonth(arrAuxOctober);
                setNovemberMonth(arrAuxNovember);
                setDecemberMonth(arrAuxDecember);
                for (let fecha of data.Dias_Descanso) {
                    let arrAux = [];
                    let aux = new Date(fecha);
                    switch (aux.getMonth()) {
                        case 0:
                            arrAuxJanuaryOff.push(aux);
                            break;
                        case 1:
                            arrAuxFebruaryOff.push(aux);

                            break;
                        case 2:
                            arrAuxMarchOff.push(aux);

                            break;
                        case 3:
                            arrAuxAprilOff.push(aux);

                            break;
                        case 4:
                            arrAuxMayOff.push(aux);

                            break;
                        case 5:
                            arrAuxJuneOff.push(aux);

                            break;
                        case 6:
                            arrAuxJulyOff.push(aux);
                            break;
                        case 7:
                            arrAuxAugustOff.push(aux);

                            break;
                        case 8:
                            arrAuxSeptemberOff.push(aux);
                            break;
                        case 9:
                            arrAuxOctoberOff.push(aux);
                            break;
                        case 10:
                            arrAuxNovemberOff.push(aux);
                            break;
                        case 11:
                            arrAuxDecemberOff.push(aux);
                            break;
                    }
                }
                setJanuaryMonthOff(arrAuxJanuaryOff);
                setFebruaryMonthOff(arrAuxFebruaryOff);
                setMarchMonthOff(arrAuxMarchOff);
                setAprilMonthOff(arrAuxAprilOff);
                setMayMonthOff(arrAuxMayOff);
                setJuneMonthOff(arrAuxJuneOff);
                setJulyMonthOff(arrAuxJulyOff);
                setAugustMonthOff(arrAuxAugustOff);
                setSeptemberMonthOff(arrAuxSeptemberOff);
                setOctoberMonthOff(arrAuxOctoberOff);
                setNovemberMonthOff(arrAuxNovemberOff);
                setDecemberMonthOff(arrAuxDecemberOff);
                forceUpdate();
            });
    };

    const handleNurseChange = (e: any, newValue: any) => {
        if(newValue) {
            setNurseNomina(newValue.payroll);
            onSelectNurse(newValue.payroll);
            getNurseClassx(newValue.payroll);
            setNewClassxSelected(nurseClassxSelected.class);
        }
    }


    const [selectedDaysType, setSelectedDaysType] = useState<string>("laboral");
    const toggleDays = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string
    ): void => {
        if (newValue !== null) setSelectedDaysType(newValue);
    };

    const isLaboralDay = (): boolean => {
        return selectedDaysType === "laboral";
    };

    return (
        <>
            <div>
                <div className="titleContainer">
                    <div className="monthContainer">
                        <div className="monthLabel">{actualYear}</div>
                        <button
                            className="monthButtons"
                            onClick={() => setActualYear(actualYear - 1)}
                            style={{ marginLeft: "2%" }}
                        >
                            <FaChevronLeft
                                style={{
                                    alignSelf: "center",
                                }}
                            ></FaChevronLeft>
                        </button>
                        <div className="dot"></div>
                        <button
                            className="monthButtons"
                            onClick={() => setActualYear(actualYear + 1)}
                            style={{ marginRight: "2%" }}
                        >
                            <FaChevronRight
                                style={{
                                    alignSelf: "center",
                                }}
                            ></FaChevronRight>
                        </button>
                    </div>
                    <div className="sectorContainer">
                        <button
                            onClick={() => setShowModal(true)}
                            className="changeDocumentButton"
                        >
                            <ExcelIcon
                                className="iconColorOptions"
                                style={{
                                    marginRight: "0.5vw",
                                }}
                            ></ExcelIcon>
                            Subir Documento
                        </button>
                        <Autocomplete
                            id="seleccionarprofesional"
                            options={nursesByHospital.sort((a: any, b: any) => a.name.localeCompare(b.name))}
                            getOptionLabel={(option: any) => option.name}
                            onChange={handleNurseChange}
                            size="small"
                            sx={{ width: '100%'}}
                            renderInput={(params) => <TextField {...params} label="Seleccionar Profesional" variant="outlined"></TextField>} 
                            />
                        <Autocomplete
                            id="seleccionarclassx"
                            options={classx.sort((a: any, b: any) => a.localeCompare(b))}
                            getOptionLabel={(option: any) => option}
                            onChange={(e, newValue) => {
                                if (newValue != null) {
                                    setNewClassxSelected(newValue);
                                    setNurseClassxSelected({class: newValue});
                                } else {
                                    setNewClassxSelected("");
                                }
                            }}
                            size="small"
                            sx={{ 
                                width: '60%',
                                marginLeft: '2%'
                            }}
                            renderInput={(params) => <TextField {...params}  label="Categoría" variant="outlined"></TextField>} 
                            value={
                                nurseClassxSelected != null 
                                    ? nurseClassxSelected.class
                                    : ""   
                            }
                            />
                        <button
                            disabled={nurseNomina === ""}
                            className="button-actualizar-calendars"
                            style={{
                                opacity: isUpdating ? 0.4 : 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => sendChanges()}
                        >
                            <ActualizarIcon
                                fill="white"
                                style={{
                                    marginRight: "0.5vw",
                                }}
                            ></ActualizarIcon>
                            Actualizar
                        </button>
                    </div>
                </div>
                <div className="labels-container">
                    <ToggleButtonGroup
                        value={selectedDaysType}
                        exclusive
                        onChange={toggleDays}
                    >
                        <ToggleButton
                            className={`label-container ${
                                selectedDaysType === "laboral"
                                    ? "active-option"
                                    : "disabled-option"
                            }`}
                            value={"laboral"}
                        >
                            <div className="first-indicator" />
                            Días laborales
                        </ToggleButton>
                        <ToggleButton
                            className={`label-container ${
                                selectedDaysType === "no-laboral"
                                    ? "active-option--no-laboral"
                                    : "disabled-option"
                            }`}
                            value={"no-laboral"}
                        >
                            <div className="second-indicator" />
                            Días no laborales
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div
                    style={{
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        marginTop: "1.7vh",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 0)}
                            month={new Date(actualYear, 0)}
                            selected={
                                isLaboralDay() ? januaryMonth : januaryMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setJanuaryMonth
                                    : setJanuaryMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? januaryMonthOff
                                    : januaryMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 1)}
                            month={new Date(actualYear, 1)}
                            selected={
                                isLaboralDay()
                                    ? februaryMonth
                                    : februaryMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setFebruaryMonth
                                    : setFebruaryMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? februaryMonthOff
                                    : februaryMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 2)}
                            month={new Date(actualYear, 2)}
                            selected={
                                isLaboralDay() ? marchMonth : marchMonthOff
                            }
                            disableNavigation
                            onSelect={
                                isLaboralDay()
                                    ? setMarchMonth
                                    : setMarchMonthOff
                            }
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? marchMonthOff
                                    : marchMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 3)}
                            month={new Date(actualYear, 3)}
                            selected={
                                isLaboralDay() ? aprilMonth : aprilMonthOff
                            }
                            disableNavigation
                            onSelect={
                                isLaboralDay()
                                    ? setAprilMonth
                                    : setAprilMonthOff
                            }
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? aprilMonthOff
                                    : aprilMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 4)}
                            month={new Date(actualYear, 4)}
                            selected={isLaboralDay() ? mayMonth : mayMonthOff}
                            onSelect={
                                isLaboralDay() ? setMayMonth : setMayMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? mayMonthOff
                                    : mayMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 5)}
                            month={new Date(actualYear, 5)}
                            selected={isLaboralDay() ? juneMonth : juneMonthOff}
                            onSelect={
                                isLaboralDay() ? setJuneMonth : setJuneMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? juneMonthOff
                                    : juneMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 6)}
                            month={new Date(actualYear, 6)}
                            selected={isLaboralDay() ? julyMonth : julyMonthOff}
                            onSelect={
                                isLaboralDay() ? setJulyMonth : setJulyMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? julyMonthOff
                                    : julyMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 7)}
                            month={new Date(actualYear, 7)}
                            selected={
                                isLaboralDay() ? augustMonth : augustMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setAugustMonth
                                    : setAugustMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? augustMonthOff
                                    : augustMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 8)}
                            month={new Date(actualYear, 8)}
                            selected={
                                isLaboralDay()
                                    ? septemberMonth
                                    : septemberMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setSeptemberMonth
                                    : setSeptemberMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? septemberMonthOff
                                    : septemberMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 9)}
                            month={new Date(actualYear, 9)}
                            selected={
                                isLaboralDay() ? octoberMonth : octoberMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setOctoberMonth
                                    : setOctoberMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? octoberMonthOff
                                    : octoberMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 10)}
                            month={new Date(actualYear, 10)}
                            selected={
                                isLaboralDay()
                                    ? novemberMonth
                                    : novemberMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setNovemberMonth
                                    : setNovemberMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? novemberMonthOff
                                    : novemberMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            className={`${
                                isLaboralDay()
                                    ? "laboral-day"
                                    : "no-laboral-day"
                            }`}
                            defaultMonth={new Date(actualYear, 11)}
                            month={new Date(actualYear, 11)}
                            selected={
                                isLaboralDay()
                                    ? decemberMonth
                                    : decemberMonthOff
                            }
                            onSelect={
                                isLaboralDay()
                                    ? setDecemberMonth
                                    : setDecemberMonthOff
                            }
                            disableNavigation
                            modifiers={{
                                dayOffModifier: isLaboralDay()
                                    ? decemberMonthOff
                                    : decemberMonth,
                            }}
                            modifiersStyles={{
                                dayOffModifier: isLaboralDay()
                                    ? dayOffModifierStyle
                                    : dayModifierStyle,
                            }}
                        ></DayPicker>
                    </div>
                </div>
                <Modal open={showModal} onClose={() => setShowModal(false)}>
                    <Box className="modalBody">
                        <UploadArchive></UploadArchive>
                    </Box>
                </Modal>
            </div>
            <ToastContainer
                containerId="PC"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                draggable={false}
                newestOnTop={true}
            ></ToastContainer>
        </>
    );
}
