import { ReactComponent as PatientToAssignIcon } from "../../assets/icons/patientPerAssign.svg";
import { ReactComponent as ShiftChange } from "../../assets/icons/cambio_de_turno1.svg";
import { ReactComponent as StetoscopioIcon } from "../../assets/icons/stetoscopio.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh2.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";

import NurseFunctionModal from "../../widgets/nurseFunctionModal/nurseFunctionModal";
import PatientAltaModal from "../../widgets/patientAltaModal/patientAltaModal";
import PatientActivity from "../../widgets/patientActivity/patientActivity";
import PatientsToAssignTable from "../../widgets/patientsToAssignTable";
import AddCommentModal from "../../widgets/addComment/addCommentModal";
import PatientForm from "../../widgets/patientForm/patientForm";
import HeaderComponent from "../../widgets/header/header";
import { URI_API, getHeaders } from "../../utils/config";
import PatientsTable from "../../widgets/patientsTable";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../store/reducer";
import { useSelector } from "react-redux";
import {
    PatientViewContainer,
    PatientViewContext,
} from "../../contexts/patientViewContext";
import "./patientView.scss";

export default function PatientView() {
    let navigator = useNavigate();
    const { setPatients } = useContext(PatientViewContext);
    const [showAltaModal, setShowAltaModal] = useState(false);
    const [showNurseFunctionModal, setShowNurseFunctionModal] = useState(false);
    const [showAddComment, setShowAddComment] = useState<boolean>(false);
    const [showPatientActivity, setShowPatientActivity] = useState(false);
    const [showPatientForm, setShowPatientForm] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [headers, setHeaders] = useState<any>([]);
    const [excelPatients, setExcelPatients] = useState<any[]>([]);

    const isAuthenticated: boolean = useSelector(
        (state: AppState) => state.isAuthenticated
    );

    const Table = () => {
        if (isSelected) {
            return (
                isAuthenticated && (
                    <PatientsToAssignTable
                        assignDrawer={showPatientForm}
                        showAssignDrawer={() => setShowPatientForm(true)}
                    ></PatientsToAssignTable>
                )
            );
        } else {
            return (
                isAuthenticated && (
                    <PatientsTable
                        patientForm={showPatientForm}
                        altaModalVisible={showAltaModal}
                        setAltaModalVisible={setShowAltaModal}
                        drawerController={() => setShowPatientForm(true)}
                        paController={() => setShowPatientActivity(true)}
                    ></PatientsTable>
                )
            );
        }
    };

    const Header = () => {
        if (isSelected) {
            return (
                <table className="mainTable table-header">
                    <tr>
                        <th style={{ width: "5%" }} className="th-first">
                            Acciones
                        </th>
                        <th style={{ width: "9.5%" }} className="th-middle">
                            Atención
                        </th>
                        <th style={{ width: "40.5%" }} className="th-middle">
                            Nombre del paciente
                        </th>
                        <th style={{ width: "33%" }} className="th-middle">
                            Sector
                        </th>
                        <th className="th-last">Habitación</th>
                    </tr>
                </table>
            );
        } else {
            return (
                <table className="mainTable table-header">
                    <thead
                        style={{
                            borderBottom: "2px solid #E3E3E3",
                        }}
                    >
                        <tr>
                            <th style={{ width: "6%" }} className="th-first">
                                Acciones
                            </th>
                            <th style={{ width: "5%" }} className="th-middle">
                                Atención
                            </th>
                            <th style={{ width: "18%" }} className="th-middle">
                                Nombre del paciente
                            </th>
                            <th style={{ width: "14%" }} className="th-middle">
                                Sector
                            </th>
                            <th style={{ width: "5%" }} className="th-middle">
                                Cama
                            </th>
                            <th style={{ width: "10%" }} className="th-middle">
                                Tipo de atención
                            </th>
                            <th
                                style={{ width: "14.5%" }}
                                className="th-middle"
                            >
                                Profesional
                            </th>
                            <th style={{ width: "16%" }} className="th-middle">
                                Profesional Auxiliar
                            </th>
                            <th className="th-last">Fecha de Ingreso</th>
                        </tr>
                    </thead>
                </table>
            );
        }
    };

    useEffect(() => {
        if (isAuthenticated)
            fetch(`${URI_API}/patients`, getHeaders())
                .then((res) => res.json())
                .then((body) => {
                    for (let patient of body) {
                        delete patient.id;
                        delete patient.patient_number;
                        delete patient.categoryid;
                        delete patient.categorymaxvalue;
                        delete patient.area_id;
                        delete patient.nurse_id;
                        delete patient.egress_date;
                        delete patient.secondarynurse_id;
                    }
                    let headers = [
                        {
                            label: "Nombre",
                            key: "name",
                        },
                        {
                            label: "Categoría",
                            key: "categoryname",
                        },
                        {
                            label: "Fecha de ingreso",
                            key: "date_of_admission",
                        },
                        {
                            label: "Sector",
                            key: "areaname",
                        },
                        {
                            label: "Profesional",
                            key: "nursename",
                        },
                        {
                            label: "Cuarto",
                            key: "room",
                        },
                        {
                            label: "Profesional auxiliar",
                            key: "secondarynursename",
                        },
                    ];
                    setHeaders(headers);
                    setExcelPatients(body);
                });
    }, [showPatientForm, showAltaModal, isAuthenticated]);

    return (
        <PatientViewContainer>
            <>
                {isAuthenticated && (
                    <PatientForm
                        showComponent={showPatientForm}
                        closeComponent={() => setShowPatientForm(false)}
                        mode={isSelected}
                    ></PatientForm>
                )}

                {isAuthenticated && (
                    <PatientActivity
                        showComponent={showPatientActivity}
                        closeComponent={() => setShowPatientActivity(false)}
                        openAddComment={() => setShowAddComment(true)}
                    ></PatientActivity>
                )}

                {isAuthenticated && (
                    <NurseFunctionModal
                        showComponent={showNurseFunctionModal}
                        closeComponent={() => setShowNurseFunctionModal(false)}
                    ></NurseFunctionModal>
                )}

                {isAuthenticated && (
                    <AddCommentModal
                        showComponent={showAddComment}
                        closeComponent={() => setShowAddComment(false)}
                    ></AddCommentModal>
                )}

                {isAuthenticated && (
                    <PatientAltaModal
                        showComponent={showAltaModal}
                        closeComponent={() => setShowAltaModal(false)}
                    ></PatientAltaModal>
                )}

                <div className="GeneralContainer">
                    <HeaderComponent showSearch={true}></HeaderComponent>
                    <div className="actionBarContainer">
                        <div style={{ flex: "9", display: "flex" }}>
                            <button
                                className="buttonAction"
                                onClick={() => setShowNurseFunctionModal(true)}
                            >
                                <StetoscopioIcon className="iconColor"></StetoscopioIcon>
                                &nbsp;&nbsp;Funciones de Enfermería
                            </button>
                            <button
                                className={
                                    isSelected
                                        ? "buttonActionSelected"
                                        : "buttonAction"
                                }
                                onClick={() => {
                                    setIsSelected(!isSelected);
                                }}
                            >
                                <PatientToAssignIcon
                                    className={
                                        isSelected
                                            ? "iconColorSelected"
                                            : "iconColor"
                                    }
                                ></PatientToAssignIcon>
                                &nbsp;&nbsp;Pacientes por Asignar
                                {isSelected && (
                                    <text style={{ marginLeft: "10px" }}>
                                        X
                                    </text>
                                )}
                            </button>
                            {/* {isSelected ? (
                                <button
                                    className="buttonAction"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        // color: "#62098C",
                                    }}
                                    onClick={() => {
                                        fetch(
                                            `${URI_API}/patients/1`,
                                            getHeaders("POST", {})
                                        ).then((res) => res.json());
                                        fetch(
                                            `${URI_API}/patients/unassigned`,
                                            getHeaders()
                                        )
                                            .then((res) => res.json())
                                            .then((body) => {
                                                setPatients(body);
                                            });
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "27px",
                                            width: "27px",
                                            borderRadius: "27px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <RefreshIcon className="iconColor"></RefreshIcon>
                                    </div>
                                    &nbsp;Actualizar
                                </button>
                            ) : (
                                <div></div>
                            )} */}
                        </div>
                        <div
                            style={{
                                flex: "4",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <CSVLink
                                data={excelPatients}
                                headers={headers}
                                filename="patients.csv"
                                className="buttonActionOptions"
                            >
                                <ExcelIcon className="iconColorOptions"></ExcelIcon>
                                &nbsp; &nbsp;Exportar a Excel
                            </CSVLink>
                            <button
                                className="buttonActionOptions"
                                onClick={() => navigator("/changeShift")}
                            >
                                <ShiftChange
                                    fill="#505050"
                                    style={{
                                        width: "20px",
                                    }}
                                ></ShiftChange>
                                &nbsp; &nbsp;Cambio de turno
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            marginBottom: "",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                        }}
                    >
                        {Header()}
                    </div>
                    <div
                        className="custom-scrollbar"
                        style={{
                            height: window.innerHeight - 240,
                            overflow: "auto",
                            scrollbarWidth: "thin",
                        }}
                    >
                        {Table()}
                    </div>
                </div>
                <ToastContainer
                    enableMultiContainer
                    containerId={"PV"}
                    autoClose={2000}
                    position="top-right"
                ></ToastContainer>
            </>
        </PatientViewContainer>
    );
}
