import { ReactComponent as CommentPatientIcon } from "../assets/icons/commentPatient.svg";
import { ReactComponent as EditPatientIcon } from "../assets/icons/editPatient.svg";
import { ReactComponent as AltaPatientIcon } from "../assets/icons/altaPatient.svg";
import { PatientViewContext } from "../contexts/patientViewContext";
import { useContext, useEffect, useState } from "react";
import { LinearProgress, Tooltip } from "@mui/material";
import { URI_API, getHeaders } from "../utils/config";
import { AppState, setSelectedPatient } from "../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import "./patientsTable.scss";
import { v4 as uuid } from "uuid";
import { emptySelectedPatient } from "../store/interfaces";
import { Dispatch } from "redux";

export default function PatientsTable(props: {
    patientForm: any;
    drawerController: any;
    paController: any;
    altaModalVisible: any;
    setAltaModalVisible: any;
}) {
    const { setSelectedArea, query, patients, setPatients } =
        useContext(PatientViewContext);

    const [tableLoading, setTableLoading] = useState<boolean>(true);

    const isAuthenticated: boolean = useSelector(
        (state: AppState) => state.isAuthenticated
    );

    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        if (isAuthenticated)
            fetch(`${URI_API}/patients`, getHeaders())
                .then((res) => res.json())
                .then((body) => {
                    setPatients(body);
                    setTableLoading(false);
                });
    }, [props.altaModalVisible, props.patientForm, isAuthenticated]);

    function mergeObjects(obj1: any, obj2: any): any {
        const mergedObj = { ...obj1 };

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (obj2[key] !== null && obj2[key] !== "") {
                    mergedObj[key] = obj2[key];
                }
            }
        }

        return mergedObj;
    }

    const selectPatient = (patient: any): void => {
        let selectedPatient: any = {
            id: patient["id"],
            name: patient["name"],
            attentionId: patient["patient_number"],
            room: patient["room"],
            dateAdmission: patient["date_of_admission"],
            area_id: patient["area_id"],
            areaName: patient["areaname"],
            nurseName: patient.nursename,
            secondaryNurseName: patient.secondarynursename
        };
        selectedPatient = mergeObjects(emptySelectedPatient, selectedPatient);
        dispatch(setSelectedPatient(selectedPatient));
    };

    return (
        <>
            {!tableLoading && (
                <table className="mainTable">
                    <tbody>
                        {patients
                            .sort((a, b) => {
                                const stringA = a.date_of_admission
                                    .split("/")
                                    .reverse();
                                const stringB = b.date_of_admission
                                    .split("/")
                                    .reverse();
                                const fechaA = new Date(
                                    parseInt(stringA[0]) > 2000
                                        ? parseInt(stringA[0])
                                        : parseInt(stringA[0]) + 2000,
                                    parseInt(stringA[1]),
                                    parseInt(stringA[2])
                                );
                                const fechaB = new Date(
                                    parseInt(stringB[0]) > 2000
                                        ? parseInt(stringB[0])
                                        : parseInt(stringB[0]) + 2000,
                                    parseInt(stringB[1]),
                                    parseInt(stringB[2])
                                );
                                return fechaB.getTime() - fechaA.getTime();
                            })
                            .filter((patient) => {
                                if (query === "") {
                                    return patient;
                                } else if (
                                    patient.name
                                        .toUpperCase()
                                        .includes(query.toUpperCase()) ||
                                    (patient.room && patient.room
                                        .toString()
                                        .toUpperCase()
                                        .includes(query.toUpperCase())) ||
                                    patient.areaname
                                        .toUpperCase()
                                        .includes(query.toUpperCase())
                                ) {
                                    return patient;
                                }
                            })
                            .map((patient) => {
                                return (
                                    <tr key={uuid()}>
                                        <td className="actions-container">
                                            <div className="actions-container__wrapper">
                                                <div>
                                                    <Tooltip
                                                        title="Editar paciente"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <button
                                                            className="button-icon"
                                                            onClick={() => {
                                                                selectPatient(
                                                                    patient
                                                                );
                                                                setSelectedArea(
                                                                    patient.area_id
                                                                );
                                                                props.drawerController();
                                                            }}
                                                        >
                                                            <EditPatientIcon></EditPatientIcon>
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <Tooltip
                                                        title="Actividad del paciente"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <button
                                                            className="button-icon"
                                                            onClick={() => {
                                                                selectPatient(
                                                                    patient
                                                                );
                                                                setSelectedArea(
                                                                    patient.area_id
                                                                );
                                                                props.paController();
                                                            }}
                                                        >
                                                            <CommentPatientIcon></CommentPatientIcon>
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <Tooltip
                                                        title="Dar de alta"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <button
                                                            className="button-icon"
                                                            onClick={() => {
                                                                selectPatient(
                                                                    patient
                                                                );
                                                                props.setAltaModalVisible(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <AltaPatientIcon></AltaPatientIcon>
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                width: "5%",
                                                textAlign: "left",
                                            }}
                                        >
                                            {patient.patient_number}
                                        </td>
                                        <td style={{ width: "18%" }}>
                                            {patient.name}
                                        </td>
                                        <td style={{ width: "14.5%" }}>
                                            {patient.areaname}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                            {patient.room}
                                        </td>
                                        <td style={{ width: "10%" }}>
                                            {patient.tiss_value !== null ? 'TISS ' + patient.tiss_value : patient.ntiss_value !== null ? 'NTISS ' + patient.ntiss_value : patient.categoryname}
                                        </td>
                                        <td style={{ width: "14.5%" }}>
                                            {patient.nursename}
                                        </td>
                                        <td
                                            style={{
                                                width: "18%",
                                                textAlign: "left",
                                                opacity:
                                                    patient.secondarynursename !==
                                                    null
                                                        ? "1"
                                                        : "0.7",
                                                userSelect:
                                                    patient.secondarynursename !==
                                                    null
                                                        ? "text"
                                                        : "none",
                                            }}
                                        >
                                            {patient.secondarynursename !== null
                                                ? patient.secondarynursename
                                                : "Sin profesional auxiliar"}
                                        </td>
                                        <td>{patient.date_of_admission}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            )}
            {tableLoading && (
                <LinearProgress
                    color="secondary"
                    style={{
                        marginTop: "1%",
                    }}
                ></LinearProgress>
            )}
        </>
    );
}
